<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header  :quickSearch.sync="filters.search">
            Imported Products
            <template slot="actions">
                <mercur-button
                    v-if="isAllowedTo('SupplierCentral/CreateSupplierProductMappingTemplate')"
                    :to="addProductMappingTemplateRoute"
                    class="btn btn-yellow text-uppercase"
                    data-test="supplierProductImportAdd"
                >
                    <i class="fas fa-plus"></i>
                    Import new product
                </mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            ref="grid"
            :quickSearch="filters.search"
        ></data-table>

        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            Are you sure you want to delete this imported product?
            <div slot="footer">
                <mercur-button class="btn" @click="deleteConfirmationDialogActive = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click.native="revokeProduct" :disabled="loading">Delete</mercur-button>
            </div>
        </mercur-dialog>

    </mercur-card>
</template>
<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
export default {
    name: 'ImportedProductsOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            deleteConfirmationDialogActive: false,
            draft: null,
            loading: false,
            isPublishing: false,
            options: {
                columns: {
                    'Product name': {
                        field: 'productName',
                        link: (value, data) => {
                            return {
                                name: 'SupplierImportedProductEdit',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierProductImportedId: data.supplierProductImportedId,
                                },
                            }
                        },
                    },
                    'Attributes': {
                        field: 'attributeConfiguration',
                        cellClass: 'ag-grid__cell--multi-line',
                        autoHeight: true,
                        valueGetter: ({ data }) => {
                            if (!data.attributeConfiguration) {
                                return '-'
                            }
                            return Object.keys(data.attributeConfiguration).join(', ')
                        },
                    },
                    'Status': {
                        field: 'productImportStatus',
                        statusChip: true,
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'Last change by': {
                        field: 'changedByAccountName',
                        sortable: true,
                    },
                    'Imported rows': {
                        field: 'importedRowsCount',
                        sortable: true,
                    },
                    'Costs sum': {
                        field: 'costsSum',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        text: 'Download template file',
                        onClick: ({ data }) => this.downloadTemplateFile(data),
                        disabled: ({ data }) => this.loading || (!data.attributeConfiguration && !data.productConfigurationTemplateId),
                    },
                    {
                        text: 'Delete imported product',
                        onClick: ({ data }) => this.triggerDelete(data),
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/revokeSupplierProductToImport'),
                    },
                    {
                        text: 'Upload csv',
                        to: ({ data }) => {
                            return {
                                name: 'SupplierImportedProductUpload',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierProductImportedId: data.supplierProductImportedId,
                                },
                            }
                        },
                    },
                    {
                        text: 'Map columns',
                        to: ({ data }) => {
                            return {
                                name: 'SupplierImportedProductCsvFileColumnMapping',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierProductImportedId: data.supplierProductImportedId,
                                },
                            }
                        },
                    },
                    {
                        text: 'Map values',
                        to: ({ data }) => {
                            return {
                                name: 'SupplierImportedProductCsvFileValueMapping',
                                params: {
                                    supplierId: data.supplierId,
                                    supplierProductImportedId: data.supplierProductImportedId,
                                },
                            }
                        },
                    },
                    {
                        text: 'Product Import Report',
                        onClick: ({ data }) => this.downloadImportReport(data),
                        disabled: ({ data }) => this.loading || !this.isAllowedTo('SupplierCentral/generateSupplierProductImportedReport'),
                    },
                    {
                        text: 'Publish',
                        isIcon: true,
                        icon: 'fas fa-upload',
                        hide: ({ data }) => data.productImportStatus !== 'AWAITING_PUBLICATION',
                        tooltipText: 'Publish product',
                        onClick: ({ data }) => this.publishProduct(data),
                        disabled: () => this.isPublishing,
                    },
                    {
                        text: 'Clone',
                        isIcon: true,
                        icon: 'fas fa-copy',
                        tooltipText: 'Clone imported product',
                        onClick: ({ data }) => this.cloneProduct(data),
                        disabled: () => this.isPublishing,
                    },
                ],
                quickSearchColumns: ['productName'],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
            filters: {
                search: '',
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.LIST.replace('{supplierId}', this.supplierId)
        },
        addProductMappingTemplateRoute () {
            return {
                name: 'SupplierImportedProductAdd',
                params: {
                    supplierId: this.supplierId,
                    supplierProductImportedId: 'new',
                },
            }
        },
    },
    methods: {
        downloadTemplateFile (data) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.GENERATE_TEMPLATE.replace('{supplierId}', this.supplierId)
            const payload = {}
            if (data.productConfigurationTemplateId) {
                payload.productConfigurationTemplateId = data.productConfigurationTemplateId
            }
            if (data.attributeConfiguration) {
                payload.attributes = data.attributeConfiguration
            }
            this.loading = true
            this.addJob(url)

            this.$store.dispatch('tasks/addTask', {
                title: `Download imported product templates csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: false,
                onComplete: data => {
                    if (data.url) {
                        this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.url)
                    }
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, payload).catch(data => {
                        this.$root.$emit('notification:global', {
                            message: `Downloading failed. Please try again.`,
                            type: 'error',
                            errors: data,
                        })
                    }).finally(() => {
                        this.loading = false
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
        triggerDelete (data) {
            this.$set(this, 'draft', data)
            this.deleteConfirmationDialogActive = true
        },
        revokeProduct () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.REVOKE
                .replace('{supplierId}', this.supplierId)
                .replace('{supplierProductImportedId}', this.draft.supplierProductImportedId)

            this.loading = true
            this.addJob(url)
            this.$api.delete(url).then(() => {
                this.$refs.grid.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: `Imported product was deleted`,
                })
            }).finally(() => {
                this.$set(this, 'draft', null)
                this.loading = false
                this.finishJob(url)
            })
        },
        publishProduct (data) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.PUBLISH
                .replace('{supplierId}', data.supplierId)
                .replace('{supplierProductImportedId}', data.supplierProductImportedId)
            this.isPublishing = true
            this.$api.post(url, data).then((res) => {
                this.$refs.grid.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: `Product was published`,
                    type: 'success',
                })
            }).catch((err) => {
                console.error(err)
                this.$root.$emit('notification:global', {
                    message: `Publishing failed.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.isPublishing = false
            })
        },
        cloneProduct (data) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.CLONE
                .replace('{supplierId}', data.supplierId)
                .replace('{supplierProductImportedId}', data.supplierProductImportedId)
            this.isPublishing = true
            this.$api.post(url, data).then((res) => {
                this.$refs.grid.refreshGrid()
                this.$root.$emit('notification:global', {
                    message: `Product was cloned`,
                    type: 'success',
                })
            }).catch((err) => {
                console.error(err)
                this.$root.$emit('notification:global', {
                    message: `Cloning failed.`,
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.isPublishing = false
            })
        },
        downloadImportReport (product) {
            const url = CONFIG.API.ROUTES.SUPPLIERS.IMPORTED_PRODUCTS.GENERATE_REPORT
                .replace('{supplierId}', product.supplierId)
                .replace('{supplierProductImportedId}', product.supplierProductImportedId)
            this.loading = true
            this.addJob(url)
            this.$store.dispatch('tasks/addTask', {
                title: `Download import report csv`,
                interval: CONFIG.CHECK_TOKEN_RESPONSE_TIMEOUT,
                attempts: CONFIG.CHECK_TOKEN_RESPONSE_ATTEMPTS,
                retryOnFail: false,
                onComplete: data => {
                    if (data.data.url) {
                        this.$bus.$emit('DOWNLOAD_URL_RESOLVED', data.data.url)
                    }
                },
                onPoll: () => {
                    this.$root.$emit('notification:global', {
                        message: `Download started.`,
                    })
                    return this.$api.post(url, {}).finally(() => {
                        this.loading = false
                        this.finishJob(url)
                    })
                },
                handleResult: (result) => {
                    if (result.data.length === 0) {
                        return Promise.resolve(null)
                    }
                    return Promise.resolve(result.data)
                },
            })
        },
    },
}
</script>
